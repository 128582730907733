<template>
  <card
    ref="card"
    actions
    block
    :title="$tc('app.plan')"
    :loading="loading"
    @reload="fetch"
  >
    <template #body>
      <data-table
        :data="plans"
        :pages="pages"
        :columns="[
          {
            key: 'service',
            label: 'product',
          },
          {
            key: 'name',
            label: 'plan',
          },
          {
            key: 'pivot.pricing.fee',
            label: 'price_estimate',
            filters: ['currency'],
          },
        ]"
        @change-page="fetch"
      >
        <template #actions="item">
          <router-link
            :to="{ name: 'BillingPlan', params: { planId: item.id } }"
          >
            <i class="fas fa-eye"></i>
          </router-link>
        </template>
      </data-table>
    </template>
  </card>
</template>

<script>
// @ is an alias to /src
import Card from '@/components/Card.vue';
import DataTable from '@/components/DataTable.vue';

export default {
  name: 'PlansCard',
  components: {
    Card,
    DataTable,
  },
  data() {
    return {
      loading: true,
      page: 1,
      pages: 1,
      plans: [],
    };
  },
  computed: {
    account() {
      return this.$store.state.account;
    },
  },
  created() {
    this.fetch();
  },
  methods: {
    fetch(page = this.page) {
      this.page = page;
      this.loading = true;
      this.$store.dispatch('fetchPlans', { page }).then((response) => {
        this.plans = response.data;
        this.pages = response.last_page;
        this.loading = false;
      });
    },
  },
};
</script>
