<template>
  <card
    actions
    block
    :title="$tc('app.usage_record', 2)"
    :loading="loading"
    @reload="fetch"
  >
    <template #body>
      <data-table
        :data="usageRecords"
        :pages="pages"
        :columns="[
          'description',
          {
            key: 'count',
            label: 'quantity',
          },
          {
            key: 'price',
            label: 'cost',
            filters: ['currency'],
          },
        ]"
        @change-page="fetch"
      >
        <template #buttons>
          <div>
            <date-range-picker
              class="form-control"
              :options="options"
              @change="changeDate"
            />
          </div>
        </template>
      </data-table>
    </template>
  </card>
</template>

<script>
// @ is an alias to /src
import moment from 'moment';
import Card from '@/components/Card.vue';
import DataTable from '@/components/DataTable.vue';

export default {
  name: 'UsageRecordsCard',
  components: {
    Card,
    DataTable,
  },
  data() {
    return {
      loading: true,
      form: {
        date: {
          gte: moment().format('YYYY-MM-DD'),
          lte: moment().format('YYYY-MM-DD 23:59:59'),
        },
      },
      usageRecords: [],
      page: 1,
      pages: 1,
      options: {
        timePicker: false,
        autoApply: true,
        className: 'form-control',
        maxSpan: {
          days: 31,
        },
        locale: {
          format: 'DD/MM/YYYY',
          daysOfWeek: ['Do', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sa'],
          monthNames: [
            'Jan',
            'Fev',
            'Mar',
            'Abr',
            'Mai',
            'Jun',
            'Jul',
            'Ago',
            'Set',
            'Otu',
            'Nov',
            'Dez',
          ],
        },
      },
    };
  },
  computed: {
    account() {
      return this.$store.state.account;
    },
  },
  created() {
    this.fetch();
  },
  methods: {
    fetch(page = this.page) {
      this.page = page;
      this.loading = true;
      this.$store
        .dispatch('fetchUsage', { page, ...this.form })
        .then((response) => {
          this.usageRecords = response.data;
          this.pages = response.last_page;
          this.loading = false;
        });
    },
    changeDate(date) {
      this.form.date.gte = moment(date[0], 'DD/MM/YYYY').format(
        'YYYY-MM-DD 00:00:00',
      );
      this.form.date.lte = moment(date[1], 'DD/MM/YYYY').format(
        'YYYY-MM-DD 23:59:59',
      );
      this.fetch();
    },
  },
};
</script>
