var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('card',{attrs:{"actions":"","block":"","title":_vm.$tc('app.usage_record', 2),"loading":_vm.loading},on:{"reload":_vm.fetch},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('data-table',{attrs:{"data":_vm.usageRecords,"pages":_vm.pages,"columns":[
        'description',
        {
          key: 'count',
          label: 'quantity',
        },
        {
          key: 'price',
          label: 'cost',
          filters: ['currency'],
        } ]},on:{"change-page":_vm.fetch},scopedSlots:_vm._u([{key:"buttons",fn:function(){return [_c('div',[_c('date-range-picker',{staticClass:"form-control",attrs:{"options":_vm.options},on:{"change":_vm.changeDate}})],1)]},proxy:true}])})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }