<template>
  <div class="content">
    <PageHeader :title="$tc('app.billing')" />
    <div class="page-content container-fluid">
      <div class="row">
        <div class="col col-lg-3 col-xl-2">
          <card class="card bg-success text-center" no-header>
            <template #body>
              <h4 class="card-title text-white">{{ $tc('balance') }}</h4>
              <p class="card-text text-white">
                {{ account.balance | currency }}
              </p>
            </template>
          </card>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <usage-records-card />
        </div>
        <div class="col-lg-6">
          <invoices-card />
        </div>
        <div class="col-lg-6">
          <plans-card />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from 'moment';
import PageHeader from '@/components/PageHeader.vue';
import Card from '@/components/Card.vue';
import UsageRecordsCard from '@/components/cards/UsageRecordsCard.vue';
import InvoicesCard from '@/components/cards/InvoicesCard.vue';
import PlansCard from '@/components/cards/PlansCard.vue';

export default {
  name: 'Billing',
  components: {
    PageHeader,
    Card,
    UsageRecordsCard,
    InvoicesCard,
    PlansCard,
  },
  data() {
    return {
      invoices: {},
      loaded: false,
      months: [],
      date: '',
      usage: [],
      options: {
        timePicker: false,
        autoApply: true,
        className: 'form-control',
        maxSpan: {
          days: 31,
        },
        locale: {
          format: 'DD/MM/YYYY',
          daysOfWeek: ['Do', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sa'],
          monthNames: [
            'Jan',
            'Fev',
            'Mar',
            'Abr',
            'Mai',
            'Jun',
            'Jul',
            'Ago',
            'Set',
            'Otu',
            'Nov',
            'Dez',
          ],
        },
      },
      form: {
        date: {
          gte: moment().format('YYYY-MM-DD'),
          lte: moment().format('YYYY-MM-DD'),
        },
      },
    };
  },
  computed: {
    account() {
      return this.$store.state.account;
    },
  },
  created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>
