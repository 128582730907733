var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('card',{ref:"card",attrs:{"actions":"","block":"","title":_vm.$tc('app.plan'),"loading":_vm.loading},on:{"reload":_vm.fetch},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('data-table',{attrs:{"data":_vm.plans,"pages":_vm.pages,"columns":[
        {
          key: 'service',
          label: 'product',
        },
        {
          key: 'name',
          label: 'plan',
        },
        {
          key: 'pivot.pricing.fee',
          label: 'price_estimate',
          filters: ['currency'],
        } ]},on:{"change-page":_vm.fetch},scopedSlots:_vm._u([{key:"actions",fn:function(item){return [_c('router-link',{attrs:{"to":{ name: 'BillingPlan', params: { planId: item.id } }}},[_c('i',{staticClass:"fas fa-eye"})])]}}])})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }